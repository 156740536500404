.kn-header {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .77), hsla(0, 0%, 100%, .64));
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 6.65px 5.32px #00000005, 0 12.522px 10.017px #00000008, 0 22.336px 17.869px #00000008, 0 41.778px 33.422px #0000000a, 0 100px 80px #0000000d;
    height: 80px;
    left: 14px;
    padding: 13px 60px;
    position: fixed;
    right: 14px;
    top: 0;
    transition: all .2s;
    width: calc(100% - 28px);
    z-index: 100
    
}

.kn-header.kn-header-blue {
    left: 0;
    right: 0;
    width: 100%
}

.kn-header-container {
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: space-between;
    line-height: 54px;
    min-width: 1020px
}

.kn-header-container .kn-aside-cont {
    width: 166px
}

.kn-header-container .kn-aside-cont .kn-logo {
    display: block;
    font-size: 0;
    height: 40px;
    width: 166px
}

.kn-header-container .kn-aside-cont .kn-logo>img {
    height: 100%
}

.kn-header-container .kn-nav-list {
    display: flex;
    justify-content: space-around
}

.kn-header-container .kn-nav-list .kn-nav-list-item {
    height: 38px;
    line-height: 38px;
    margin: 0 20px
}

.kn-header-container .kn-nav-list .kn-nav-list-item a {
    border-radius: 4px;
    color: #222;
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    padding: 8px 16px;
    text-decoration: none
}

.kn-header-container .kn-nav-list .kn-nav-list-item a:hover,
.kn-header-container .kn-nav-list .kn-nav-list-item.active a {
    color: #4c48e2
}

.kn-login-register-btn-login {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px
}

.kn-login-register-btn-register {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background-color: #4b48e6;
    border-color: #4b48e6;
    color: #fff;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0 0 0 16px;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
}

.kn-login-register-btn-register:hover {
    background-color: #4b48e6b3;
    border-color: #4b48e6b3;
}


@media screen and (width: 1440px) { 
    .kn-header  {
        height: 180px;
    }

    .kn-header-container .kn-aside-cont .kn-logo {
        height: 80px;
        width: 256px
    }
    
    .kn-header-container {
        height: 130px;
        line-height: 130px;
    }

    .kn-header-container .kn-nav-list .kn-nav-list-item a {
        height: 54px;
        font-size: 28px;
        line-height: 54px;
    }

    .kn-login-register-btn {
        margin-top: 16px;
    }

    .kn-login-register-btn-login,.kn-login-register-btn-register {
        font-size: 28px;
    }
}